import * as Sentry from "@sentry/nextjs";

function report(message: string) {
  console.error(message);
  Sentry.captureMessage(message);
}

function reportError(error: Error) {
  console.error(error);
  Sentry.captureException(error);
}

function throwUnlessSucccess(response: Response) {
  if (response.status > 299 || response.status < 200) {
    throw new Error(
      `Expected success response but got ${response.status} ${response.statusText}`
    );
  }
}

export { report, reportError, throwUnlessSucccess };
