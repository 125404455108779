import type {ChangeEvent} from "react";
import type {InstanceWithExtensions, SDKBase} from "@magic-sdk/provider";

import {Magic} from "magic-sdk";
import {OAuthExtension} from "@magic-ext/oauth";
import {useRouter} from "next/router";
import Image from "next/image";
import {Box, Button, Card, Container, Grid, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import _ from "lodash";

import PublicLayout from "../layout/PublicLayout";
import {reportError, throwUnlessSucccess} from "../../lib/sentry";

type MagicLink = InstanceWithExtensions<SDKBase, OAuthExtension[]>;

export default function SignIn() {
  const [disabled, setDisabled] = useState(true);
  const [emailInput, setEmailInput] = useState("");
  const router = useRouter();
  let m: MagicLink;

  function handleEmailChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setEmailInput(event.target.value);
    setDisabled(false);
  }

  useEffect(() => {
    m = new Magic(process.env.NEXT_PUBLIC_MAGIC_PUBLISHABLE_KEY!, {
      extensions: [new OAuthExtension()],
    });

    if (_.isEmpty(emailInput)) {
      setDisabled(true);
    }
  });

  async function handleSubmit(event: any) {
    try {
      event.preventDefault();
      const email = emailInput;
      const redirectURI = `${window.location.origin}/loginCallback`;
      const did = await m.auth.loginWithMagicLink({email, redirectURI});

      const authRequest = await fetch("/api/auth/login", {
        method: "POST",
        headers: {Authorization: `Bearer ${did}`},
      });

      throwUnlessSucccess(authRequest);

      const {path} = await authRequest.json()

      if (path) {
        router.push(path)
      } else {
        router.push("/error");
      }
    } catch (error: unknown) {
      reportError(error as Error);
    }
  }

  async function onClickGoogle() {
    const magic = new Magic(process.env.NEXT_PUBLIC_MAGIC_PUBLISHABLE_KEY!, {
      extensions: [new OAuthExtension()],
    });

    await magic.oauth.loginWithRedirect({
      provider: "google",
      redirectURI: `${window.location.origin}/api/auth/google`,
    });
  }

  return (
    <Grid>
      <Grid item xs={12}>
        <PublicLayout />
      </Grid>
      <Grid
        item
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        md={6}
        mt={10}
        sx={{mt: {xs: 10, md: 20}}}
        xs={8}
      >
        <Image alt="logo" height={118} src="/kp-sin-flecha.jpg" width={420} />
        <Typography
          fontSize={{
            md: 30,
            xs: 24,
          }}
          sx={{textAlign: "center", mt: 8, color: "#163D26"}}
        >
          Iniciar sesión
        </Typography>
        <Card
          sx={{
            width: {xs: "80%", md: "25%"},
            padding: "3%",
          }}
        >
          <Container>
            <Typography
              align="center"
              component="span"
              fontSize={{
                md: 15,
                xs: 15,
              }}
            >
              <Box>
                <div>
                  <TextField
                    fullWidth
                    label="Ingrese su email"
                    name="email"
                    size="small"
                    sx={{backgroundColor: "#ffffff"}}
                    type="email"
                    onChange={(e) => handleEmailChange(e)}
                  />
                </div>
                <Button
                  fullWidth
                  color="primary"
                  disabled={disabled}
                  sx={{mt: 4, color: "#EDEDED"}}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Enviar link de acceso
                </Button>
                <Box display="flex" flexDirection="row" margin="5% 0%" maxWidth="100%">
                  <hr style={{width: "45%", height: "1px"}} /> O{" "}
                  <hr style={{width: "45%", height: "1px"}} />
                </Box>

                <Button
                  startIcon={
                    <Image
                      alt="google icon"
                      height={30}
                      src="/google-icon.png"
                      style={{borderRadius: "50%"}}
                      width={30}
                    />
                  }
                  sx={{
                    width: "100%",
                    color: "#3B3747",
                    padding: "2%",
                  }}
                  variant="outlined"
                  onClick={onClickGoogle}
                >
                  Iniciar sesión con Google
                </Button>
              </Box>
            </Typography>
          </Container>
        </Card>
      </Grid>
    </Grid>
  );
}
