import useSWR from "swr";

function fetcher(route: any) {
  return fetch(route)
    .then((r) => r.ok && r.json())
    .then((user) => user || null);
}

export default function useAuth() {
  const {data: user, error, mutate} = useSWR("/api/auth/user", fetcher);
  const loading = user === undefined;

  return {
    user,
    loading,
    error,
  };
}
