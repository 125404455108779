import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItemText from "@mui/material/ListItemText";
import DescriptionIcon from "@mui/icons-material/Description";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Image from "next/image";
import Link from "next/link";
import {useRouter} from "next/router";
import {useState} from "react";
import {Magic} from "magic-sdk";
import {Button, Tooltip} from "@mui/material";

import {reportError} from "../../lib/sentry";
import CookieService from "../../lib/cookie";
import styles from "../../styles/Home.module.css";

const drawerWidth = 200;

export default function ResponsiveDrawer({email}: {email: string}) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const router = useRouter();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const m = new Magic(process.env.NEXT_PUBLIC_MAGIC_PUBLISHABLE_KEY!);

  async function logOut() {
    try {
      await m.user.logout();
      CookieService.removeAuthedCookie();
      await router.push("/api/auth/logout");
    } catch (error: unknown) {
      reportError(error as Error);
      router.push("/error");
    }
  }

  const drawer = (
    <div
      style={{
        backgroundColor: "#18a3c9",
        height: "100%",
        color: "#EDEDED",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div>
        <Toolbar
          sx={{
            height: {xs: 90, md: 75},
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <svg height="40" viewBox="0 0 592 592" width="40" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M327.267 267.75c-13.146 14.712-25.381 28.438-27.188 30.5l-3.286 3.75 68.853-.023 68.854-.024 24.5-27.582c13.475-15.17 25.545-28.885 26.822-30.477l2.323-2.894H351.168l-23.901 26.75"
                fill="currentColor"
                fillRule="evenodd"
              />
              <path
                d="M434.667 194.933C427.7 202.596 422 209.121 422 209.433c0 .312 21.111.567 46.914.567h46.914l12.586-13.905c6.922-7.648 12.586-14.173 12.586-14.5 0-.327-21.075-.595-46.833-.595h-46.833l-12.667 13.933"
                fill="#525352"
                fillRule="evenodd"
              />
              <path
                d="m421.295 337.745-10.205.324-19.622 20.965L371.845 380H429.612l16.944-18.723c9.319-10.297 17.997-19.972 19.283-21.5l2.339-2.777-18.339.211c-10.086.116-22.931.356-28.544.534"
                fill="#000000"
                fillRule="evenodd"
              />
              <path
                d="M157.143 359.18C146.614 370.829 138 380.803 138 381.344c0 .642 22.988.754 66.165.32 36.391-.365 83.099-.664 103.796-.664h37.63l18.705-20.957c10.287-11.526 18.704-21.201 18.704-21.5 0-.298-46.511-.543-103.357-.543H176.285l-19.142 21.18"
                fill="#2e9a79"
                fillRule="evenodd"
                // naranja f4941c
              />
              <path
                d="M88.087 215.106c-18.377 18.646-33.219 34.095-32.982 34.332.237.237 57.348.164 126.913-.163l126.482-.593 34.486-33.841L377.472 181l-127.986.103-127.986.103-33.413 33.9"
                fill="#f4941c"
                fillRule="evenodd"
                // verde 2e9a79
              />
            </svg>
            <svg
              height="40"
              viewBox="0 0 180.27 42.48"
              width="120"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="#0ea5e9"
                fillRule="evenodd"
                fontSize="9pt"
                id="svgGroup"
                stroke="#0ea5e9"
                strokeLinecap="round"
                strokeWidth="0.40mm"
              >
                <path
                  d="M 1.35 32.49 L 0 32.49 L 0 0 L 1.35 0 L 1.35 24.57 L 1.53 24.57 L 16.2 10.98 L 18 10.98 L 1.35 26.28 L 1.35 32.49 Z M 19.53 32.49 L 17.91 32.49 L 7.65 19.62 L 8.64 18.63 L 19.53 32.49 Z"
                  fill="currentColor"
                  id="0"
                  stroke="currentColor"
                  vectorEffect="non-scaling-stroke"
                />
                <path
                  d="M 27.27 14.76 L 26.55 13.68 Q 27.315 13.095 28.732 12.33 Q 30.15 11.565 31.972 11.003 Q 33.795 10.44 35.73 10.44 A 12.016 12.016 0 0 1 37.574 10.573 Q 39.206 10.827 40.342 11.565 Q 42.075 12.69 42.863 14.535 A 9.939 9.939 0 0 1 43.638 18.005 A 11.538 11.538 0 0 1 43.65 18.54 L 43.65 32.49 L 42.48 32.49 L 42.3 29.16 L 42.21 29.16 Q 40.32 30.69 37.89 31.86 A 11.921 11.921 0 0 1 35.004 32.837 A 10.263 10.263 0 0 1 33.03 33.03 Q 31.14 33.03 29.408 32.423 A 6.84 6.84 0 0 1 27.509 31.415 A 6.138 6.138 0 0 1 26.573 30.532 A 4.471 4.471 0 0 1 25.579 28.425 A 6.312 6.312 0 0 1 25.47 27.225 A 5.348 5.348 0 0 1 27.651 22.836 A 9.223 9.223 0 0 1 29.43 21.758 A 18.165 18.165 0 0 1 32.049 20.791 Q 35.896 19.662 42.3 19.08 A 10.91 10.91 0 0 0 41.803 15.767 A 10.309 10.309 0 0 0 41.715 15.502 Q 41.13 13.815 39.69 12.758 Q 38.25 11.7 35.64 11.7 Q 33.93 11.7 32.332 12.195 A 16.43 16.43 0 0 0 30.362 12.942 A 13.848 13.848 0 0 0 29.43 13.41 A 27.046 27.046 0 0 0 28.515 13.937 Q 28.071 14.205 27.696 14.46 A 13.273 13.273 0 0 0 27.27 14.76 Z M 42.3 27.63 L 42.3 20.25 A 91.972 91.972 0 0 0 38.909 20.625 Q 35.44 21.078 33.12 21.713 A 21.148 21.148 0 0 0 31.376 22.265 Q 29.657 22.9 28.663 23.693 A 5.085 5.085 0 0 0 28.328 23.985 Q 26.91 25.335 26.91 27.18 Q 26.91 28.845 27.832 29.858 A 5.143 5.143 0 0 0 29.826 31.199 A 6.216 6.216 0 0 0 30.172 31.32 Q 31.59 31.77 33.12 31.77 A 9.958 9.958 0 0 0 35.978 31.335 A 12.787 12.787 0 0 0 37.575 30.735 A 16.879 16.879 0 0 0 39.867 29.465 Q 40.916 28.779 41.992 27.889 A 28.434 28.434 0 0 0 42.3 27.63 Z"
                  fill="currentColor"
                  id="1"
                  stroke="currentColor"
                  vectorEffect="non-scaling-stroke"
                />
                <path
                  d="M 53.1 32.49 L 51.75 32.49 L 51.75 10.98 L 52.92 10.98 L 53.1 13.95 L 53.19 13.95 Q 54.055 12.535 55.005 11.635 A 7.546 7.546 0 0 1 55.282 11.385 Q 56.385 10.44 58.185 10.44 Q 60.03 10.44 61.065 11.385 A 4.026 4.026 0 0 1 61.859 12.41 Q 62.132 12.9 62.333 13.517 A 8.979 8.979 0 0 1 62.55 14.31 Q 63.435 12.982 64.265 12.098 A 9.194 9.194 0 0 1 64.958 11.43 A 4.004 4.004 0 0 1 66.887 10.528 A 5.61 5.61 0 0 1 67.905 10.44 Q 70.155 10.44 71.348 12.015 Q 72.493 13.528 72.538 16.579 A 17.082 17.082 0 0 1 72.54 16.83 L 72.54 32.49 L 71.19 32.49 L 71.19 17.01 A 12.503 12.503 0 0 0 71.071 15.205 Q 70.557 11.7 67.815 11.7 Q 66.24 11.7 65.115 12.78 A 8.397 8.397 0 0 0 64.336 13.65 Q 63.588 14.603 62.82 16.02 L 62.82 32.49 L 61.47 32.49 L 61.47 17.01 A 13.644 13.644 0 0 0 61.404 15.622 Q 61.236 13.982 60.637 13.028 A 2.689 2.689 0 0 0 58.705 11.747 A 3.837 3.837 0 0 0 58.095 11.7 Q 56.52 11.7 55.395 12.78 A 8.397 8.397 0 0 0 54.616 13.65 Q 53.868 14.603 53.1 16.02 L 53.1 32.49 Z"
                  fill="currentColor"
                  id="2"
                  stroke="currentColor"
                  vectorEffect="non-scaling-stroke"
                />
                <path
                  d="M 92.07 32.49 L 90.72 32.49 L 90.72 12.24 L 79.65 12.24 L 79.65 10.98 L 92.07 10.98 L 92.07 32.49 Z M 90.9 5.49 Q 90.18 5.49 89.64 4.973 A 1.724 1.724 0 0 1 89.122 3.933 A 2.413 2.413 0 0 1 89.1 3.6 A 2.406 2.406 0 0 1 89.164 3.029 A 1.605 1.605 0 0 1 89.64 2.205 Q 90.18 1.71 90.9 1.71 Q 91.665 1.71 92.183 2.205 Q 92.7 2.7 92.7 3.6 A 2.293 2.293 0 0 1 92.624 4.205 A 1.697 1.697 0 0 1 92.182 4.972 A 1.738 1.738 0 0 1 90.924 5.49 A 2.244 2.244 0 0 1 90.9 5.49 Z"
                  fill="currentColor"
                  id="3"
                  stroke="currentColor"
                  vectorEffect="non-scaling-stroke"
                />
                <path
                  d="M 109.17 32.49 L 107.73 32.49 L 107.73 2.79 L 115.47 2.79 A 23.532 23.532 0 0 1 117.966 2.915 Q 119.732 3.104 121.14 3.578 A 7.804 7.804 0 0 1 123.029 4.494 A 6.195 6.195 0 0 1 124.695 6.143 A 6.415 6.415 0 0 1 125.567 8.071 Q 125.803 8.924 125.877 9.937 A 13.141 13.141 0 0 1 125.91 10.89 A 10.901 10.901 0 0 1 125.725 12.957 Q 125.435 14.454 124.695 15.593 A 7.047 7.047 0 0 1 122.175 17.911 A 9.096 9.096 0 0 1 121.14 18.405 A 12.144 12.144 0 0 1 118.729 19.083 Q 117.239 19.35 115.47 19.35 L 109.17 19.35 L 109.17 32.49 Z M 109.17 4.05 L 109.17 18.09 L 114.93 18.09 A 21.972 21.972 0 0 0 117.441 17.957 Q 120.397 17.615 122.063 16.403 A 5.311 5.311 0 0 0 124.026 13.552 Q 124.38 12.384 124.38 10.89 Q 124.38 6.975 122.063 5.513 A 7.674 7.674 0 0 0 120.199 4.687 Q 118.394 4.137 115.779 4.062 A 29.536 29.536 0 0 0 114.93 4.05 L 109.17 4.05 Z"
                  fill="#525252"
                  id="4"
                  stroke="#525252"
                  vectorEffect="non-scaling-stroke"
                />
                <path
                  d="M 135.27 14.76 L 134.55 13.68 Q 135.315 13.095 136.733 12.33 Q 138.15 11.565 139.973 11.003 Q 141.795 10.44 143.73 10.44 A 12.016 12.016 0 0 1 145.574 10.573 Q 147.206 10.827 148.343 11.565 Q 150.075 12.69 150.863 14.535 A 9.939 9.939 0 0 1 151.638 18.005 A 11.538 11.538 0 0 1 151.65 18.54 L 151.65 32.49 L 150.48 32.49 L 150.3 29.16 L 150.21 29.16 Q 148.32 30.69 145.89 31.86 A 11.921 11.921 0 0 1 143.004 32.837 A 10.263 10.263 0 0 1 141.03 33.03 Q 139.14 33.03 137.408 32.423 A 6.84 6.84 0 0 1 135.509 31.415 A 6.138 6.138 0 0 1 134.573 30.532 A 4.471 4.471 0 0 1 133.579 28.425 A 6.312 6.312 0 0 1 133.47 27.225 A 5.348 5.348 0 0 1 135.651 22.836 A 9.223 9.223 0 0 1 137.43 21.758 A 18.165 18.165 0 0 1 140.049 20.791 Q 143.896 19.662 150.3 19.08 A 10.91 10.91 0 0 0 149.803 15.767 A 10.309 10.309 0 0 0 149.715 15.502 Q 149.13 13.815 147.69 12.758 Q 146.25 11.7 143.64 11.7 Q 141.93 11.7 140.333 12.195 A 16.43 16.43 0 0 0 138.362 12.942 A 13.848 13.848 0 0 0 137.43 13.41 A 27.046 27.046 0 0 0 136.515 13.937 Q 136.071 14.205 135.696 14.46 A 13.273 13.273 0 0 0 135.27 14.76 Z M 150.3 27.63 L 150.3 20.25 A 91.972 91.972 0 0 0 146.909 20.625 Q 143.44 21.078 141.12 21.713 A 21.148 21.148 0 0 0 139.376 22.265 Q 137.657 22.9 136.663 23.693 A 5.085 5.085 0 0 0 136.328 23.985 Q 134.91 25.335 134.91 27.18 Q 134.91 28.845 135.833 29.858 A 5.143 5.143 0 0 0 137.826 31.199 A 6.216 6.216 0 0 0 138.172 31.32 Q 139.59 31.77 141.12 31.77 A 9.958 9.958 0 0 0 143.978 31.335 A 12.787 12.787 0 0 0 145.575 30.735 A 16.879 16.879 0 0 0 147.867 29.465 Q 148.916 28.779 149.992 27.889 A 28.434 28.434 0 0 0 150.3 27.63 Z"
                  fill="#525252"
                  id="5"
                  stroke="#525252"
                  vectorEffect="non-scaling-stroke"
                />
                <path
                  d="M 159.165 42.03 L 159.615 40.77 Q 159.925 40.873 160.341 40.964 A 11.408 11.408 0 0 0 160.605 41.018 Q 161.19 41.13 161.775 41.13 A 6.36 6.36 0 0 0 163.99 40.757 A 5.635 5.635 0 0 0 166.095 39.375 A 12.223 12.223 0 0 0 168.108 36.594 A 15.291 15.291 0 0 0 168.84 35.01 L 169.65 33.03 L 159.03 10.98 L 160.47 10.98 L 167.13 25.11 Q 167.76 26.46 168.57 28.08 A 73.64 73.64 0 0 0 169.785 30.409 A 64.097 64.097 0 0 0 170.19 31.14 L 170.37 31.14 A 73.984 73.984 0 0 0 171.361 28.928 A 85.666 85.666 0 0 0 171.72 28.08 Q 172.395 26.46 172.98 25.11 L 178.92 10.98 L 180.27 10.98 L 169.92 35.55 A 13.309 13.309 0 0 1 168.751 38.01 A 15.519 15.519 0 0 1 168.232 38.812 Q 167.13 40.41 165.488 41.445 A 6.709 6.709 0 0 1 162.707 42.415 A 8.578 8.578 0 0 1 161.64 42.48 A 6.017 6.017 0 0 1 161.026 42.447 Q 160.718 42.415 160.38 42.353 A 9.847 9.847 0 0 1 160.335 42.345 A 9.475 9.475 0 0 1 159.872 42.247 Q 159.647 42.193 159.457 42.133 A 4.078 4.078 0 0 1 159.165 42.03 Z"
                  fill="#525252"
                  id="6"
                  stroke="#525252"
                  vectorEffect="non-scaling-stroke"
                />
              </g>
            </svg>
          </div>

          <IconButton sx={{display: {lg: "none"}}} onClick={handleDrawerToggle}>
            <ChevronLeftIcon sx={{ml: "0px", color: "#EDEDED"}} />
          </IconButton>
        </Toolbar>
        <List
          sx={{
            marginTop: "24px",
          }}
        >
          <Link
            href="/"
            style={{
              textDecoration: "none",
              color: "#EDEDED",
            }}
          >
            <ListItem key="home" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <HomeIcon sx={{fontSize: 25, color: "#EDEDED"}} />
                  <ListItemText primary="Inicio" sx={{color: "#EDEDED", ml: 2}} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </Link>
          <Link
            href="/dashboard"
            style={{
              textDecoration: "none",
              color: "#EDEDED",
            }}
          >
            <ListItem key="dash" disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <DescriptionIcon sx={{fontSize: 25, color: "#EDEDED"}} />
                  <ListItemText primary="Transacciones" sx={{color: "#EDEDED", ml: 2}} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </Link>
          <ListItem key="logout" disablePadding>
            <ListItemButton onClick={logOut}>
              <ListItemIcon>
                <LogoutIcon sx={{fontSize: 25, color: "#EDEDED"}} />
                <ListItemText primary="Cerrar sesión" sx={{color: "#EDEDED", ml: 2}} />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
      </div>

      <Tooltip title={email}>
        <Button
          color="primary"
          sx={{
            mb: 3,
            color: "#EDEDED",
            height: {xs: 34.75, md: 36.5},
            fontSize: {xs: "13px", md: "14px"},
            textTransform: "lowercase",
          }}
        >
          {`${email.length > 24 ? email.slice(0, 20) + "..." : email}`}
        </Button>
      </Tooltip>
    </div>
  );

  return (
    <Box sx={{display: "flex"}}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: {lg: `calc(100% - ${drawerWidth}px)`},
          ml: {lg: `${drawerWidth}px`},
        }}
      >
        <div
          style={{
            backgroundColor: "#f3941f",
            height: "100%",
            color: "#EDEDED",
          }}
        >
          <Toolbar
            sx={{
              height: 50,
              backgroundColor: "#f3941f",
            }}
          >
            <div>
              <IconButton
                aria-label="open drawer"
                color="inherit"
                edge="start"
                sx={{ml: 0, display: {lg: "none"}}}
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </div>
            <div className={styles.toolbarLogo}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <svg
                  height="40"
                  viewBox="0 0 592 592"
                  width="40"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M327.267 267.75c-13.146 14.712-25.381 28.438-27.188 30.5l-3.286 3.75 68.853-.023 68.854-.024 24.5-27.582c13.475-15.17 25.545-28.885 26.822-30.477l2.323-2.894H351.168l-23.901 26.75"
                    fill="currentColor"
                    fillRule="evenodd"
                  />
                  <path
                    d="M434.667 194.933C427.7 202.596 422 209.121 422 209.433c0 .312 21.111.567 46.914.567h46.914l12.586-13.905c6.922-7.648 12.586-14.173 12.586-14.5 0-.327-21.075-.595-46.833-.595h-46.833l-12.667 13.933"
                    fill="#525352"
                    fillRule="evenodd"
                  />
                  <path
                    d="m421.295 337.745-10.205.324-19.622 20.965L371.845 380H429.612l16.944-18.723c9.319-10.297 17.997-19.972 19.283-21.5l2.339-2.777-18.339.211c-10.086.116-22.931.356-28.544.534"
                    fill="#000000"
                    fillRule="evenodd"
                  />
                  <path
                    d="M157.143 359.18C146.614 370.829 138 380.803 138 381.344c0 .642 22.988.754 66.165.32 36.391-.365 83.099-.664 103.796-.664h37.63l18.705-20.957c10.287-11.526 18.704-21.201 18.704-21.5 0-.298-46.511-.543-103.357-.543H176.285l-19.142 21.18"
                    fill="#17a4c9"
                    fillRule="evenodd"
                  />
                  <path
                    d="M88.087 215.106c-18.377 18.646-33.219 34.095-32.982 34.332.237.237 57.348.164 126.913-.163l126.482-.593 34.486-33.841L377.472 181l-127.986.103-127.986.103-33.413 33.9"
                    fill="#2e9a79"
                    fillRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </Toolbar>
        </div>
      </AppBar>
      <Box
        aria-label="mailbox folders"
        component="nav"
        sx={{
          width: {md: drawerWidth},
          flexShrink: {sm: 0},
        }}
      >
        <Drawer
          ModalProps={{
            keepMounted: true,
          }}
          open={mobileOpen}
          sx={{
            display: {xs: "block", lg: "none"},
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          variant="temporary"
          onClose={handleDrawerToggle}
        >
          {drawer}
        </Drawer>
        <Drawer
          open
          sx={{
            display: {xs: "none", lg: "block"},
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          variant="permanent"
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: {xs: 1, md: 3},
          width: {sm: `calc(100% - ${drawerWidth}px)`},
        }}
      >
        <Toolbar />
      </Box>
    </Box>
  );
}
