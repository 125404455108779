import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Image from "next/image";

import styles from "../../styles/Home.module.css";

export default function PublicLayout() {
  return (
    <AppBar position="fixed">
      <div
        style={{
          backgroundColor: "#f3941f",
          height: "100%",
          color: "#EDEDED",
        }}
      >
        <Toolbar
          sx={{
            height: 50,
          }}
        >
          <div className={styles.toolbarLogo}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <svg height="40" viewBox="0 0 592 592" width="40" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M327.267 267.75c-13.146 14.712-25.381 28.438-27.188 30.5l-3.286 3.75 68.853-.023 68.854-.024 24.5-27.582c13.475-15.17 25.545-28.885 26.822-30.477l2.323-2.894H351.168l-23.901 26.75"
                  fill="currentColor"
                  fillRule="evenodd"
                />
                <path
                  d="M434.667 194.933C427.7 202.596 422 209.121 422 209.433c0 .312 21.111.567 46.914.567h46.914l12.586-13.905c6.922-7.648 12.586-14.173 12.586-14.5 0-.327-21.075-.595-46.833-.595h-46.833l-12.667 13.933"
                  fill="#525352"
                  fillRule="evenodd"
                />
                <path
                  d="m421.295 337.745-10.205.324-19.622 20.965L371.845 380H429.612l16.944-18.723c9.319-10.297 17.997-19.972 19.283-21.5l2.339-2.777-18.339.211c-10.086.116-22.931.356-28.544.534"
                  fill="#000000"
                  fillRule="evenodd"
                />
                <path
                  d="M157.143 359.18C146.614 370.829 138 380.803 138 381.344c0 .642 22.988.754 66.165.32 36.391-.365 83.099-.664 103.796-.664h37.63l18.705-20.957c10.287-11.526 18.704-21.201 18.704-21.5 0-.298-46.511-.543-103.357-.543H176.285l-19.142 21.18"
                  fill="#17a4c9"
                  fillRule="evenodd"
                />
                <path
                  d="M88.087 215.106c-18.377 18.646-33.219 34.095-32.982 34.332.237.237 57.348.164 126.913-.163l126.482-.593 34.486-33.841L377.472 181l-127.986.103-127.986.103-33.413 33.9"
                  fill="#2e9a79"
                  fillRule="evenodd"
                />
              </svg>
            </div>
          </div>
        </Toolbar>
      </div>
    </AppBar>
  );
}
