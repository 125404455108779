import "../styles/globals.css";
import type {AppProps} from "next/app";

import {createTheme, ThemeProvider} from "@mui/material";
import Head from "next/head";
import {Analytics} from "@vercel/analytics/react";

import Layout from "../components/layout/Layout";

const theme = createTheme({
  palette: {
    primary: {
      main: "#18a3c9",
    },
    secondary: {
      main: "#f3941f",
    },
  },
});

export default function App({Component, pageProps}: AppProps) {
  return (
    <>
      <Head>
        <title>kamiPay</title>
        <link href="/favicon.ico" rel="shortcut icon" />
      </Head>
      <ThemeProvider theme={theme}>
        <Layout>
          <Component {...pageProps} />
        </Layout>
        <Analytics />
      </ThemeProvider>
    </>
  );
}
