import type {NextApiResponse} from "next";

import {serialize} from "cookie";
import Iron from "@hapi/iron";

const TOKEN_NAME = "api_token";
const AUTHED_COOKIE = "authed";
const MAX_AGE = 60 * 60 * 24 * 30;

function createCookie(name: any, data: any, options = {}) {
  return serialize(name, data, {
    maxAge: MAX_AGE,
    expires: new Date(Date.now() + MAX_AGE * 1000),
    secure: process.env.NODE_ENV === "production",
    path: "/",
    httpOnly: true,
    sameSite: "lax",
    ...options,
  });
}

function setTokenCookie(res: NextApiResponse, token: string) {
  res.setHeader("Set-Cookie", getCookieHeaders(token));
}

function getCookieHeaders(token: string): string[] {
  return [createCookie(TOKEN_NAME, token), createCookie(AUTHED_COOKIE, true, {httpOnly: false})];
}

function getAuthToken(cookies: any) {
  return cookies[TOKEN_NAME];
}

function removeAuthedCookie() {
  document.cookie = `${AUTHED_COOKIE}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

async function getUserData(req: any) {
  const cookies = req.cookies;
  const tokenCookie = getAuthToken(cookies);

  return Iron.unseal(tokenCookie, process.env.ENCRYPTION_SECRET!, Iron.defaults);
}

export async function getUserData2(tokenCookie: string) {
  return Iron.unseal(tokenCookie, process.env.ENCRYPTION_SECRET!, Iron.defaults);
}

function removeApiCookie(res: any) {
  const cookie = serialize(TOKEN_NAME, "", {
    maxAge: -1,
    path: "/",
  });

  res.setHeader("Set-Cookie", cookie);
}

export default {
  setTokenCookie,
  getAuthToken,
  removeApiCookie,
  removeAuthedCookie,
  getUserData,
  getCookieHeaders,
  TOKEN_NAME,
};
