import {cloneElement} from "react";
import {useRouter} from "next/router";
import _ from "lodash";

import SignIn from "../auth/signIn";
import styles from "../../styles/Home.module.css";
import useAuth from "../../hooks/useAuth";
import Loader from "../Loader";

import PublicLayout from "./PublicLayout";
import Drawer from "./Drawer";

function noSignedInLayout(path: string) {
  const pages = ["signUp", "oauth", "error"];

  return pages.some((page) => path.includes(page));
}

export default function Layout({children}: any) {
  const {user, loading} = useAuth();
  const router = useRouter();
  const noSignedIn = noSignedInLayout(router.pathname);
  const isPymnt = router.pathname.includes("pymnt");
  const isLoginCallback = router.pathname.includes("loginCallback");
  const {redirected} = router.query;

  return (
    <>
      {isPymnt ? <div>{cloneElement(children)}</div> : null}
      {isLoginCallback ? <div>{cloneElement(children)}</div> : null}
      {redirected ? <PublicLayout /> : null}
      {noSignedIn ? (
        <>
          <PublicLayout />
          <div>{cloneElement(children)}</div>
        </>
      ) : null}
      {loading ? <Loader /> : null}
      {user && !loading && !noSignedIn && !isPymnt && !isLoginCallback ? (
        <>
          <Drawer email={user.email} />
          <div className={styles.container}>
            <div>{cloneElement(children)}</div>
          </div>
        </>
      ) : null}
      {!user && !loading && !noSignedIn && !isPymnt && !isLoginCallback && <SignIn />}
    </>
  );
}
